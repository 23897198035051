import React from 'react'
import { Link } from 'gatsby'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Stack from 'react-bootstrap/Stack'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import Layout from '/src/components/layout'
import { RegistrationForm } from '/src/components/user'
import HeroImage from '/src/components/layout/HeroImage'

const RegisterPage = () => {
  return (
    <Layout hero={<HeroImage title="Register" />}>
      <Row className="d-flex justify-content-center">
        <Col md={10} lg={8}>
          <Row className="mt-2 mb-4">
            <Col sm={6}>
              <div className="page-title">
                <h1 className="title">Register</h1>
              </div>
            </Col>
            <Col sm={6} className="d-flex justify-content-end">
              <Stack direction="horizontal" gap={4}>
                <label className="text-blue">Have an account?</label>
                <Link className="button outline" to="/user/login">
                  Login
                  <FontAwesomeIcon icon={faArrowRight} />
                </Link>
              </Stack>
            </Col>
          </Row>
          <RegistrationForm />
        </Col>
      </Row>
    </Layout>
  )
}

export default RegisterPage

export const Head = () => <title>Register | Metalcorp</title>
